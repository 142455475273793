import React, { useContext, useEffect, useState } from 'react'
import I from 'immutable'
import {
  TableCell,
  Paper,
  TableHead,
  Grow,
  Modal,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Container,
  Input,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  TableBody,
  Table,
  CircularProgress,
  TableContainer,
  Box,
  Collapse,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Context from 'reactContext'
import Agent from 'components/agent'
import Signup from 'components/register'
import SnackMessage from 'components/snackbar'
import { post, get } from 'utils/request'
import { formattedTime } from 'utils'

const UserList = () => {
  const { usersSet, viewSetIn, view, users, self } = useContext(Context)
  const [index, setIndex] = useState('managers')
  const [userLoading, setUserLoading] = useState(true)
  const [rechargeLoading, setRechargeLoading] = useState(false)
  const [recharges, setRecharges] = useState({})
  const [selectedManager, setSelectedManager] = useState('')
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const [id, setId] = useState('')
  const [balance, setBalance] = useState('')
  const [awardDialog, setAwardDialog] = useState(false)

  const useStyles = makeStyles((theme) => ({
    paper: {
      outline: 0,
      padding: 20,
      width: 380,
      maxWidth: '100%',
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
    },
  }))
  const classes = useStyles()

  const usersIndex = async (type = index) => {
    const res = await get(`agents/${type}`)
    if (res.ok) {
      usersSet(I.fromJS(res.data))
    }
    setUserLoading(false)
  }

  const getRecharges = async () => {
    const res = await get(`agents/managers/recharges`)
    if (res.ok) {
      setRecharges(res.recharges)
      setRechargeLoading(false)
    }
  }

  const qiyetongReward = async () => {
    const res = await post(`agents/managers/${id}/reward`, { balance })
    setAwardDialog(false)
    setOpen(true)
    if (res.ok === false) {
      setResMessage(res.message)
    } else {
      setResMessage('充值成功')
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }

  const controlButton = () => {
    if (self.get('agent_level') === 'secondary_agent') return null
    return (
      <div className="mb-6">
        <FormControl>
          <RadioGroup row defaultValue="managers">
            <FormControlLabel
              value="managers"
              control={
                <Radio
                  onChange={() => {
                    setIndex('managers')
                    setUserLoading(true)
                  }}
                />
              }
              label="所有企业"
            />
            <FormControlLabel
              value="secondary_agents"
              control={
                <Radio
                  onChange={() => {
                    setIndex('secondary_agents')
                    setUserLoading(true)
                  }}
                />
              }
              label="联盟伙伴"
            />
          </RadioGroup>
        </FormControl>
        {index === 'secondary_agents' ? (
          <Button color="primary" variant="contained" className="float-right" onClick={() => viewSetIn(['createDialogOpen'], true)}>
            添加联盟伙伴
          </Button>
        ) : null}
      </div>
    )
  }

  const selectManager = (managerId) => {
    if (managerId === selectedManager) {
      setSelectedManager('')
    } else {
      setSelectedManager(managerId)
    }
  }

  const rechargesTable = () => {
    const records = recharges[selectedManager] || []
    return (
      <TableRow>
        <TableCell colSpan={2} />
        <TableCell style={{ paddingBottom: 20, paddingTop: 0 }} colSpan={4}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                明细
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>金额(元)</TableCell>
                    <TableCell>充值时间</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.map((v) => (
                    <TableRow key={v.id}>
                      <TableCell align="left">{`¥ ${v.amount / 100}`}</TableCell>
                      <TableCell align="left">{formattedTime(v.created_at)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  const rowData = Object.values(users.toJS())

  useEffect(() => {
    usersIndex(index)
    if (index === 'managers') {
      setRechargeLoading(true)
      getRecharges()
    }
  }, [index, resMessage])

  return (
    <Agent>
      {controlButton()}
      {userLoading || rechargeLoading ? (
        <div className="text-center">
          <CircularProgress size={30} />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>名称</TableCell>
                <TableCell>邮箱</TableCell>
                <TableCell>手机</TableCell>
                <TableCell>余额（元）</TableCell>
                {index === 'managers' ? (
                  <>
                    <TableCell>充值</TableCell>
                    <TableCell>充值记录</TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData
                .filter((v) => v.name.indexOf(view.get('searchText')) !== -1)
                .map((v) => (
                  <>
                    <TableRow key={v.created_at}>
                      <TableCell align="left">{v.name}</TableCell>
                      <TableCell align="left">{v.email}</TableCell>
                      <TableCell align="left">{v.mobile}</TableCell>
                      <TableCell align="left">{`¥ ${v.balance / 100}`}</TableCell>
                      {index === 'managers' ? (
                        <>
                          <TableCell align="left">
                            <Button
                              color="primary"
                              variant="contained"
                              className="mt-1 mr-3"
                              onClick={() => {
                                setId(v.id)
                                setAwardDialog(true)
                              }}
                            >
                              充值
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              color="primary"
                              variant="contained"
                              className="mt-1 mr-3"
                              onClick={() => {
                                selectManager(v.id)
                              }}
                            >
                              {selectedManager === v.id ? '隐藏' : '展开'}
                            </Button>
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                    {selectedManager === v.id && rechargesTable()}
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Modal open={view.get('createDialogOpen')} onClose={() => viewSetIn(['createDialogOpen'], false)} className="flex items-center justify-center">
        <Grow in={view.get('createDialogOpen')}>
          <Container maxWidth="xs" className={classes.paper}>
            <Signup />
          </Container>
        </Grow>
      </Modal>
      <Dialog open={awardDialog} onClose={() => setAwardDialog(false)}>
        <DialogContent>
          <Grid container className="mb-6">
            <Grid xs item>
              <Input fullWidth autoFocus type="number" placeholder="金额" defaultValue={balance ? balance / 100 : null} onChange={(e) => setBalance(e.target.value * 100)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAwardDialog(false)} color="primary">
            取消
          </Button>
          <Button onClick={() => qiyetongReward()} color="primary">
            确定
          </Button>
        </DialogActions>
      </Dialog>
      <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
    </Agent>
  )
}

export default UserList
