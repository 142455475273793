import React, { useContext, useState } from 'react'
import { CircularProgress, Input, Container, Button } from '@material-ui/core'
import Context from 'reactContext'
import SnackMessage from 'components/snackbar'
import { post } from 'utils/request'

const Signup = () => {
  const { usersMerge, viewSetIn } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const signup = async (params) => {
    const res = await post('agents/secondary_agents', params)
    if (res.ok) {
      usersMerge(res.user)
      setOpen(true)
      setResMessage('创建成功')
      viewSetIn(['createDialogOpen'], false)
    } else {
      setOpen(true)
      setResMessage('创建失败')
    }
    setLoading(false)
  }

  return (
    <Container maxWidth="xs">
      <Input autoFocus className="mt-6" placeholder="邮箱" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
      <Input className="mt-6" fullWidth placeholder="名称" type="text" value={name} onChange={(e) => setName(e.target.value)} />
      <Input className="mt-6" placeholder="密码" type="password" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} />
      <Input className="mt-6" placeholder="确认密码" fullWidth type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        disabled={loading}
        className="mt-8 mb-4"
        onClick={() => {
          setLoading(true)
          signup({ email, name, password, confirmationpassword: passwordConfirmation })
        }}
      >
        {loading ? <CircularProgress size={24} /> : '立即创建'}
      </Button>
      <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
    </Container>
  )
}

export default Signup
